import React from "react"

function ButtonInverted(props) {
  return (
    <div className="inline-block">
      <div className="flex items-center text-base md:text-lg px-4 py-2 leading-none bg-white border border-blue-400 rounded text-blue-400 hover:bg-gray-100 hover:text-blue-500">
        <a href="tel:8322874010">
          <svg
            className="inline-block h-6 w-6 mr-2"
            viewBox="0 0 20 20"
            fill="#4299E1"
          >
            <path d="M2 3a1 1 0 011-1h2.153a1 1 0 01.986.836l.74 4.435a1 1 0 01-.54 1.06l-1.548.773a11.037 11.037 0 006.105 6.105l.774-1.548a1 1 0 011.059-.54l4.435.74a1 1 0 01.836.986V17a1 1 0 01-1 1h-2C7.82 18 2 12.18 2 5V3z" />
          </svg>
          {props.title || `Get a Free Quote`}
        </a>
      </div>
    </div>
  )
}

export default ButtonInverted
