import React from "react"
import ButtonInverted from "../components/buttonInverted"

const CTA = props => {
  return (
    <section className="fullWidth bg-blue-400 hero-top">
      <div className="container text-center py-16">
        <h2 className="text-3xl font-semibold text-white pb-8">
          {props.title || `Get a Free Quote In Minutes`}
        </h2>
        <ButtonInverted title={props.buttonTitle} />
        <h4 className="text-white pt-8 text-xl">Fast, Risk-Free Estimates</h4>
      </div>
    </section>
  )
}

export default CTA
