import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import Carousel from "nuka-carousel"
import ArrowFront from "../images/arrow-forward.svg"
import ArrowBack from "../images/arrow-backward.svg"

function Testimonials(props) {
  const data = useStaticQuery(graphql`
    query TestimonialImages {
      testimonialOne: file(relativePath: { eq: "business-man-headshot.jpg" }) {
        id
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <section id="testimonials" className="pt-16 ">
      <h2 className="text-3xl text-center text-blue-400 font-semibold">
        {props.mainTitle || `Our Customers`}
      </h2>
      <Carousel
        className="focus:outline-none "
        renderCenterLeftControls={({ previousSlide }) => (
          <button onClick={previousSlide}>
            <ArrowBack className="h-12 w-12 md:h-16 md:w-16" />
          </button>
        )}
        renderCenterRightControls={({ nextSlide }) => (
          <button onClick={nextSlide}>
            <ArrowFront className="h-12 w-12 md:h-16 md:w-16" />
          </button>
        )}
        renderBottomCenterControls={<span />}
        wrapAround="true"
        pauseOnHover="true"
        autoplay="true"
        autoplayInterval="8000"
      >
        <div className="py-16 cursor-default">
          <div className="flex justify-center">
            <Img
              className="rounded-full shadow-inner h-32 w-32"
              fluid={data.testimonialOne.childImageSharp.fluid}
              alt={props.altTagOne || "Klein Plumbing Services Testimonial 1"}
            />
          </div>
          <div className="w-5/6 mx-auto md:w-1/2 lg:w-1/3 rounded overflow-hidden shadow-lg -mt-12 border border-gray-200">
            <div className="px-6 pb-8 pt-12">
              <div className="text-blue-400 text-xs pt-4 mb-1 text-center">
                Spring, TX
              </div>
              <div className="font-bold text-2xl mb-4 text-center">Rick A.</div>
              <p className="text-gray-600 text-center text-base mb-4">
                "Sid was such a professional plumber. He was clean, quick, and
                thorough. Definitely would call again next time I need some
                plumbing work done."
              </p>
            </div>
          </div>
        </div>
        <div className="py-16 cursor-default">
          <div className="flex justify-center">
            <Img
              className="rounded-full shadow-inner h-32 w-32"
              fluid={data.testimonialOne.childImageSharp.fluid}
              alt={props.altTagTwo || "Klein Plumbing Services Testimonial 2"}
            />
          </div>
          <div className="w-5/6 mx-auto md:w-1/2 lg:w-1/3 rounded overflow-hidden shadow-lg -mt-12 border border-gray-200">
            <div className="px-6 pb-8 pt-12">
              <div className="text-blue-400 text-xs pt-4 mb-1 text-center">
                Spring, TX
              </div>
              <div className="font-bold text-2xl mb-4 text-center">Rick B.</div>
              <p className="text-gray-600 text-center text-base mb-4">
                "Sid was such a professional plumber. He was clean, quick, and
                thorough. Definitely would call again next time I need some
                plumbing work done."
              </p>
            </div>
          </div>
        </div>
        <div className="py-16 cursor-default">
          <div className="flex justify-center">
            <Img
              className="rounded-full shadow-inner h-32 w-32"
              fluid={data.testimonialOne.childImageSharp.fluid}
              alt={props.altTagThree || "Klein Plumbing Services Testimonial 3"}
            />
          </div>
          <div className="w-5/6 mx-auto md:w-1/2 lg:w-1/3 rounded overflow-hidden shadow-lg -mt-12 border border-gray-200">
            <div className="px-6 pb-8 pt-12">
              <div className="text-blue-400 text-xs pt-4 mb-1 text-center">
                Spring, TX
              </div>
              <div className="font-bold text-2xl mb-4 text-center">Rick C.</div>
              <p className="text-gray-600 text-center text-base mb-4">
                "Sid was such a professional plumber. He was clean, quick, and
                thorough. Definitely would call again next time I need some
                plumbing work done."
              </p>
            </div>
          </div>
        </div>
      </Carousel>
    </section>
  )
}

export default Testimonials
