import { Link } from "gatsby"
import React from "react"

function Footer() {
  return (
    <footer className="bg-gray-900 w-full pt-16 pb-4 pin-b">
      <div className="container">
        <div className="md:flex">
          <div className="mt-4 md:mt-0 md:w-2/5 lg:inline-block lg:mt-0 text-white text-center lg:text-left">
            <Link
              className="text-xl md:text-md lg:text-lg hover:text-gray-300"
              to={`/`}
            >
              Klein Plumbing Services
            </Link>
            <div className="text-center lg:text-left mt-2 lg:mt-4">
              <a
                href="tel:832-287-4010"
                className="text-sm hover:text-gray-600 text-gray-500"
              >
                (832) 287-4010
              </a>
            </div>
            <div className="text-center lg:text-left">
              <a
                href="mailto:sid@kleinplumbingservices.com"
                className="text-sm hover:text-gray-600 text-gray-500 mt-1"
              >
                sid@kleinplumbingservices.com
              </a>
            </div>
            <p className="text-sm text-center lg:text-left text-gray-500 mt-1">
              9607 Ballin David Dr. Spring, TX
            </p>
            <p className="text-sm text-center lg:text-left text-gray-500 mt-1">
              MPL-37333
            </p>
          </div>
          <div className="md:flex md:justify-between md:w-3/5 flex-grow md:ml-4">
            <div className="md:text-md md:flex w-full md:justify-between md:items-start">
              <div className="mt-4 md:mt-0 lg:inline-block lg:mt-0 text-white md:mr-4 lg:mr-8">
                <p className="block text-center text-xl lg:text-lg">Hours</p>
                <p className="block text-sm text-center text-gray-500 mt-2 lg:mt-4">
                  Mon - Fri: 8am - 5pm
                </p>
              </div>
              <div className="text-center mt-4 md:mt-0 lg:inline-block lg:mt-0 text-white md:mr-4 lg:mr-8">
                <p className="text-xl lg:text-lg">Menu</p>
                <div className="text-center mt-2 lg:mt-4">
                  <Link
                    to="/"
                    className="text-sm text-gray-500 hover:text-gray-600"
                  >
                    Home
                  </Link>
                </div>
                <div className="text-center mt-1">
                  <Link
                    to="/about"
                    className="text-sm text-gray-500 hover:text-gray-600"
                  >
                    About
                  </Link>
                </div>
              </div>
              <div className="text-center mt-4 md:mt-0 lg:inline-block lg:mt-0 text-white md:mr-4 lg:mr-8">
                <Link
                  to="/#services"
                  className="text-center text-xl lg:text-lg hover:text-gray-300"
                >
                  Services
                </Link>
                <div className="text-center mt-2 lg:mt-4">
                  <Link
                    to="/plumbing"
                    className="text-sm text-gray-500 hover:text-gray-600"
                  >
                    Plumbing
                  </Link>
                </div>
                <div className="text-center mt-1">
                  <Link
                    to="/water-heater-repair"
                    className="text-sm text-gray-500 hover:text-gray-600"
                  >
                    Water Heaters
                  </Link>
                </div>
                <div className="text-center mt-1">
                  <Link
                    to="/gas-line-repair"
                    className="text-sm text-gray-500 hover:text-gray-600"
                  >
                    Gas Lines
                  </Link>
                </div>
                <div className="text-center mt-1">
                  <Link
                    to="/sewers"
                    className="text-sm text-gray-500 hover:text-gray-600"
                  >
                    Sewers
                  </Link>
                </div>
              </div>
              <div className="text-center mt-4 md:mt-0 lg:inline-block lg:mt-0 text-white">
                <p className="text-xl lg:text-lg">Service Areas</p>
                <div className="block text-center mt-2 lg:mt-4">
                  <Link
                    to="/service-areas/klein-plumber"
                    className="text-sm text-gray-500 hover:text-gray-600 text-center mt-1"
                  >
                    Klein
                  </Link>
                  <span className="text-sm text-gray-700"> | </span>
                  <Link
                    to="/service-areas/spring-plumber"
                    className="text-sm text-gray-500 hover:text-gray-600 text-center mt-1"
                  >
                    Spring
                  </Link>
                </div>
                <div className="block text-center mt-1">
                  <Link
                    to="/service-areas/tomball-plumber"
                    className="text-sm text-gray-500 hover:text-gray-600 text-center mt-1"
                  >
                    Tomball
                  </Link>
                  <span className="text-sm text-gray-700"> | </span>
                  <Link
                    to="/service-areas/woodlands-plumber"
                    className="text-sm text-gray-500 hover:text-gray-600 text-center mt-1"
                  >
                    Woodlands
                  </Link>
                </div>
                <div className="block text-center mt-1">
                  <Link
                    to="/service-areas/magnolia-plumber"
                    className="text-sm text-gray-500 hover:text-gray-600 text-center mt-1"
                  >
                    Magnolia
                  </Link>
                  <span className="text-sm text-gray-700"> | </span>
                  <Link
                    to="/service-areas/cypress-plumber"
                    className="text-sm text-gray-500 hover:text-gray-600 text-center mt-1"
                  >
                    Cypress
                  </Link>
                </div>
                <div className="block text-center mt-1">
                  <Link
                    to="/service-areas/north-houston-plumber"
                    className="text-sm text-gray-500 hover:text-gray-600 text-center mt-1"
                  >
                    North Houston
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="block text-center mt-4 text-gray-700 md:flex md:justify-center md:items-center">
          <Link
            to="/"
            className="block text-sm hover:text-gray-800 text-center md:mr-4"
          >
            &copy; {new Date().getFullYear()} Klein Plumbing Services
          </Link>
          <Link
            to="/privacy"
            className="text-sm hover:text-gray-800 text-center mr-4"
          >
            Privacy
          </Link>
          <a
            href={`sitemap.xml`}
            className="text-sm hover:text-gray-800 text-center"
          >
            Sitemap
          </a>
        </div>
      </div>
    </footer>
  )
}

export default Footer
