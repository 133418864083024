import { Link } from "gatsby"
import PropTypes from "prop-types"
import React, { useState } from "react"
import Button from "./callButton"

function Header({ siteTitle }) {
  const [isExpanded, toggleExpansion] = useState(false)

  return (
    <nav className="bg-blue-400 fullWidth px-2 py-4">
      <div className="container flex items-center justify-between flex-wrap ">
        <div className="flex items-center flex-shrink-0 text-white">
          <Link
            className="text-lg lg:text-xl tracking-tight hover:text-gray-200"
            to={`/`}
          >
            {siteTitle}
          </Link>
        </div>
        <div className="block lg:hidden">
          <button
            onClick={() => toggleExpansion(!isExpanded)}
            className="flex items-center px-3 py-2 rounded text-white hover:text-gray-200 focus:outline-none"
          >
            <svg
              className="fill-current h-4 w-4"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <title>Menu</title>
              <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
            </svg>
          </button>
        </div>
        <div
          className={`${
            isExpanded ? `block` : `hidden`
          } w-full block flex-grow lg:flex lg:items-center lg:w-auto`}
        >
          <div className="text-sm md:text-base lg:flex-grow lg:flex lg:justify-end">
            <Link
              to={`/`}
              className="block mt-4 lg:inline-block lg:mt-0 text-white font-semibold hover:text-gray-200 mr-12"
            >
              Home
            </Link>
            <Link
              to={`/about`}
              className="block mt-4 lg:inline-block lg:mt-0 text-white font-semibold hover:text-gray-200 mr-12"
            >
              About
            </Link>
            <Link
              to={`/#services`}
              className="block mt-4 lg:inline-block lg:mt-0 text-white font-semibold hover:text-gray-200 mr-12"
            >
              Services
            </Link>
          </div>
          <Button />
        </div>
      </div>
    </nav>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
