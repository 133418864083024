import React from "react"

const ServiceAreas = props => {
  return (
    <section className="fullWidth">
      <div className="container text-center py-16">
        <h2 className="text-3xl text-blue-400 font-semibold">
          Klein Plumbing Service Areas
        </h2>
        <h4 className="pt-4 text-lg leading-normal text-gray-600">
          We're proud to be a member of your community. <br /> Our family-owned
          plumbing company services {props.description} in the following Houston
          areas:
        </h4>
        <div className="pt-4 text-xl font-semibold text-gray-800">
          <p>
            Klein <span className="font-normal text-gray-500">|</span> Spring{" "}
            <span className="font-normal text-gray-500">|</span> Tomball{" "}
            <span className="font-normal text-gray-500">|</span> Woodlands
          </p>
        </div>
        <div className="pt-2 text-xl font-semibold text-gray-800">
          <p>
            Magnolia <span className="font-normal text-gray-500">|</span>{" "}
            Cypress <span className="font-normal text-gray-500">|</span> North
            Houston
          </p>
        </div>
        <h4 className="pt-4 text-lg leading-normal text-gray-600">
          We can handle your {props.subDescription} quickly and easily so you
          can get back to your life.
        </h4>
      </div>
    </section>
  )
}

export default ServiceAreas
